.myProfileContainer {
  padding: 24px 0 40px;
  .whiteBox {
    width: 100%;
    max-width: 926px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #c6c6c6;
    background: #fff;

    :global(.dark) & {
      background: #1b1b1b;
      border-color: #262626;
    }
    & + .whiteBox {
      margin-top: 24px;
    }
  }
  .whiteBoxWrap {
    padding: 24px;
  }
  .titleArea {
    padding: 24px 24px 12px;
    border-bottom: 1px solid #c6c6c6;

    :global(.dark) & {
      border-color: #262626;
    }
  }
  .boxTitle {
    color: #525252;
    font-size: 18px;
    font-weight: 700;

    :global(.dark) & {
      color: #fff;
    }
  }
  .contentArea {
    display: flex;
    padding: 76px 0 140px;
  }
}
.profileValueWrap {
  display: flex;
  align-items: center;
  & + .profileValueWrap {
  }
  &.password {
    .value {
      border-bottom: 0;
    }
  }
  .label {
    width: 120px;
    margin-right: 12px;
    font-size: 14px;
    color: #909090;
    flex-shrink: 0;
    :global(.dark) & {
      color: #5d5d5d;
    }
  }
}

.value {
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid #ddd;
  :global(.dark) & {
    border-color: #484848;
  }

  .text {
    font-size: 14px;
    color: #525252;

    :global(.dark) & {
      color: #fff;
    }
  }
}
.selectTheme {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 148px;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &.light {
      & > div:first-child {
        background: #f7f7f7;
      }
    }
  }
}
.MuiFormControlLabel-root {
  margin-top: 24px;
}
