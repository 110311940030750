// Login
.login-container {
  position: relative;

  .notice-text,
  .copyright {
    color: #909090;
  }
  .dark-mode-container {
    position: absolute;
    right: 50px;
    top: 10px;
    display: flex;
    justify-content: center;

    .mode-label {
      font-size: 14px;
    }
  }
}
.login-top-area {
  margin-bottom: 2.4rem;
  .title {
    color: #a50034;
    margin-bottom: 1rem;
    text-align: center;

    .dark & {
      color: #a50034;
    }
  }
  .sub-text {
    text-align: center;
    color: #909090;
    font-size: 1.4rem;
    font-weight: 400;
  }
}
.login-input {
  + .login-input {
    margin-top: 35px;
  }
  .MuiInputBase-root {
    background: #fff;

    .dark & {
      background: #1b1b1b;
    }
  }
  .MuiInputBase-input {
    font-size: 16px;
    padding: 12px 12px 12px 0;
    height: 56px;

    &::placeholder {
      color: #b1b1b1;
    }
  }
}

.dark-mode-container {
  position: absolute;
  right: 10px;
  top: 50px;
}
