.schedule {
  .col {
    &.auto {
      max-width: none;
    }
  }
}
.program-schedule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.date-mark {
  display: flex;
  align-items: center;
  gap: 4px;

  .date {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #000;
    font-weight: 700;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url(../../images/icon/ic_calendar_24_outline.svg) no-repeat;
    }
  }
}
.program-header-wrap {
  position: sticky;
  width: 100%;
  top: 0;
  margin-bottom: -1px;
  z-index: var(--programHeader);
  background: #f7f7f7;

  .header-inner {
    display: flex;
  }
  .header-item {
    color: #909090;
    display: flex;
    flex-shrink: 0;
    width: 9%;
    padding: 11px 12px 12px 0;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    background: #f7f7f7;
    :global(.dark) & {
      background: #0c0c0c;
      border-color: #484848;
    }

    &.current {
      .text {
        font-weight: 700;
        color: #000;
      }
      .text:before {
        font-weight: 200;
        color: #909090;
      }
    }
    &:not(:first-child) {
      .text:before {
        content: '|';
        margin-right: 7px;
        display: inline-block;
      }
    }
    .text {
      font-size: 1.2rem;
      font-weight: 400;
      color: #909090;
      line-height: 16px;
    }
  }
}

.program-item-wrap {
  .row {
    display: flex;
    flex-wrap: nowrap;
    // Grid 페이지 내 Row 별 높이를 맞추기 위함
    min-height: 82px;
  }
  .program-item {
    position: relative;
    flex-shrink: 0;
    padding: 8px 12px;
    margin-bottom: -1px;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    :global(.dark) & {
      border-color: #484848;
    }

    &.past {
      .program-title {
        color: #c6c6c6;

        :global(.dark) & {
          color: #262626;
        }
      }

      :global(.dark) & {
        background: #0c0c0c;
      }

      .program-time {
        .text {
          :global(.dark) & {
            color: #262626;
          }
        }
      }
    }
    &.active {
      background: #fff;

      :global(.dark) & {
        color: #fff;
        background: #1b1b1b;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 80px;
        display: block;
        background: #a50034;
      }
      .program-title {
        font-weight: 700;

        :global(.dark) & {
          color: #fff;
        }
      }
    }
    &.tiny {
      padding: 8px 0;
    }

    :global(.dark) & {
      background: #0c0c0c;
    }
  }
  .program-title {
    color: #525252;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 8px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    :global(.dark) & {
      color: #808080;
    }
  }
  .program-time {
    font-size: 1.2rem;
    color: #909090;
    display: flex;
    align-items: center;
    line-height: 16px;
    overflow: hidden;

    :global(.dark) & {
      color: #909090;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      background: url(../../images/icon/ic_time_16_outline_red.svg) no-repeat;
    }
  }
}
.schedule-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .current-time {
    position: absolute;
    top: 0;
    z-index: var(--currentTime);
    height: 100%;

    .time-area {
      display: inline-block;
      height: 100%;
      color: #fff;
      font-size: 1.1rem;
      border-radius: 3px;

      .time-text {
        position: relative;
        display: block;
        height: 16px;
        padding: 0 9px;
        text-align: center;
        background: #a50034;
        font-size: 1rem;
        white-space: nowrap;
      }
      .time-bar {
        display: inline-block;
        position: absolute;
        left: 50%;
        width: 1px;
        height: 100%;
        background: #a50034;
      }
    }
  }

  .schedule-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    :global(.dark) & {
      background: #0c0c0c;
    }

    &::-webkit-scrollbar {
      width: 3px; /*스크롤바의 너비*/
      height: 6px;
    }
  }

  .channel-area {
    position: sticky;
    left: 0;
    top: 0;
    flex-shrink: 0;
    width: 133px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
    z-index: var(--channelArea);

    .dark & {
      background-color: #121212;
    }
    :global(.dark) & {
      background: #000;
      border-color: #484848;
    }

    .channel-list-container {
      box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.05);
    }
  }
  .channel-list-container {
    .top-area {
      position: sticky;
      top: 0;
      left: 0;
      height: 40px;
      background: #fff;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      z-index: var(--channelTopText);

      :global(.dark) & {
        background-color: #000;
        border-color: #484848;
      }
      .text {
        color: #000;
        font-size: 1.4rem;
        font-weight: 700;

        :global(.dark) & {
          color: #fff;
        }
      }
    }
    .channel-item {
      flex-direction: column;
      align-items: flex-start;
      height: 82px;
      padding: 12px;
      background: #fff;
      :global(.dark) & {
        background: #000;
      }

      .info-box {
        min-height: 56px;
      }
      &:hover {
        cursor: pointer;
      }
      .channel-number {
        padding: 2px 8px;
        border-radius: 4px;
        border: 1px solid rgba(33, 150, 243, 0.4);
        background: rgba(33, 150, 243, 0.1);
        color: #0077b5;
        font-size: 1.2rem;
      }
      .channel-name {
        min-height: 32px;
        margin-top: 4px;
        color: #a50034;
        font-weight: 400;
        line-height: 16px;
        font-size: 1.2rem;
        :global(.dark) & {
          color: #a50034;
        }

        .MuiTypography-root {
          font-size: 1.2rem;
          line-height: 16px;
        }
      }
    }
  }
  .schedule-area {
    position: relative;
    width: 100%;

    .program-wrap {
      position: relative;
      max-width: 1450px;
    }
  }
}
.schedule-body-scroll {
  overflow: visible !important;
  height: 100% !important;
}
:global {
  .channel-name .MuiTypography-root {
    font-size: 1.2rem !important;
  }
}
