@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url(../../font/Roboto-Thin.woff) format('woff2'),
       url(../../font/Roboto-Thin.woff) format('woff'),
  }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(../../font/Roboto-Light.woff2) format('woff2'),
       url(../../font/Roboto-Light.woff) format('woff'),
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
   src: url(../../font/Roboto-Regular.woff2) format('woff2'),
        url(../../font/Roboto-Regular.woff) format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
   src: url(../../font/Roboto-Medium.woff2) format('woff2'),
        url(../../font/Roboto-Medium.woff) format('woff'),
      
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
   src: url(../../font/Roboto-Bold.woff2) format('woff2'),
        url(../../font/Roboto-Bold.woff) format('woff'),
}
