@import '../common/font';

// GNB
.navi-container {
  &.MuiList-root {
    padding-bottom: 50px;
  }
  .MuiPaper-root {
    &.MuiAppBar-root {
      background: #fff;
      border-bottom: 1px solid #dbe3ef;
      box-shadow: none;
    }
    .nav-divider {
      background: #ddd;
    }
  }
  .MuiListItemText-root {
    margin: 0;

    .MuiTypography-root {
      color: #7d7d7d;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;

      .dark & {
        color: #fff;
      }
    }
  }
  .MuiDrawer-root {
    .btn-expand,
    .btn-shrink {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 10px;
      padding: 14px;
    }

    .drawer-header {
      .header-wrap {
        width: 100%;
        padding: 0;
      }
    }
    .MuiButtonBase-root {
      min-height: auto;
      padding: 0;
      &.expand-shrink {
        height: 48px;
      }
      .btn-menu-expand,
      .btn-menu-shrink {
        height: 48px;
        .MuiIconButton-root {
          padding: 8px 14px;
        }
        &.selected {
          background: #525252;
        }
      }

      &.dropdown-item {
        padding: 0 15px 0 0;
      }
      .MuiListItemButton-root {
        padding-right: 15px;
      }

      .MuiListItemIcon-root {
        padding: 14px;
        margin-right: 0;
      }
      .MuiIconButton-root {
        padding: 14px;

        &:hover {
          background: none;
        }
      }
      .icon {
        &.iss {
          background: url(../../images/icon/ic_ch_ISS2_24.svg) no-repeat;
        }
        &.inspect {
          background: url(../../images/icon/ic_ch_inspect3_24.svg) no-repeat;
        }
        &.organization {
          background: url(../../images/icon/ic_ch_service_32_outline.svg) no-repeat 50%;
        }
        &.promotion {
          background: url(../../images/icon/ic_ch_promo4_24.svg) no-repeat 50%;
        }
        &.statistics {
          background: url(../../images/icon/ic_stastistic_32_outline.svg) no-repeat 50%;
        }
        &.config {
          background: url(../../images/icon/ic_config_32_outline.svg) no-repeat 50%;
        }
        &.monitoring {
          background: url(../../images/icon/ic_check_32_outline.svg) no-repeat 50%;
        }
        &.support {
          background: url(../../images/icon/ic_menu_partner_32.svg) no-repeat 50%;
        }
        &.favorite {
          background: url(../../images/icon/ic_favorite_off_32_outline.svg) no-repeat 50%;
        }
      }
      &.selected {
        background: #a50034;

        .MuiTypography-root {
          color: #fff;
        }
        .btn-menu-expand {
          .icon {
            display: block;
            width: 32px;
            height: 32px;
            background: url(../../images/icon/ic_add_32_outline_selected.svg) no-repeat;
          }
        }

        .icon-arrow {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url(../../images/icon/ic_down_16_outline_white.svg) no-repeat;
          transform: rotate(180deg);
        }
        .icon-setting {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          display: inline-block;
          background: url(../../images/icon/ic_setting_24_outline_selected.svg) no-repeat;
        }

        .btn-menu-expand {
          .icon {
            display: block;
            width: 32px;
            height: 32px;
            background: url(../../images/icon/ic_add_32_outline_white.svg) no-repeat;
          }
        }
        .icon {
          &.iss {
            background: url(../../images/icon/ic_ch_ISS2_32_outline_selected.svg) no-repeat;
          }
          &.inspect {
            background: url(../../images/icon/ic_ch_inspect3_32_outline_selected.svg) no-repeat;
          }
          &.organization {
            background: url(../../images/icon/ic_ch_service_32_outline_selected.svg) no-repeat 50%;
          }
          &.promotion {
            background: url(../../images/icon/ic_ch_promo4_32_outline_selected.svg) no-repeat 50%;
          }
          &.statistics {
            background: url(../../images/icon/ic_stastistic_32_outline_selected.svg) no-repeat 50%;
          }
          &.config {
            background: url(../../images/icon/ic_config_32_outline_selected.svg) no-repeat 50%;
          }
          &.monitoring {
            background: url(../../images/icon/ic_check_32_outline_selected.svg) no-repeat 50%;
          }
          &.support {
            background: url(../../images/icon/ic_partner_32_outline.svg) no-repeat 50%;
          }
          &.favorite {
            background: url(../../images/icon/ic_favorite_off_32_outline_selected.svg) no-repeat 50%;
          }
        }
      }
      .icon-arrow {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url(../../images/icon/ic_right_16_outline.svg) no-repeat;
        transform: rotate(90deg);
      }
      .icon-setting {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        display: inline-block;
        background: url(../../images/icon/ic_setting_24_outline.svg) no-repeat;
      }

      .btn-menu-expand {
        padding-right: 0;
        .icon {
          display: block;
          width: 32px;
          height: 32px;
          background: url(../../images/icon/ic_add_32_outline.svg) no-repeat;
        }
        .dark & {
          .icon {
            background: url(../../images/icon/ic_add_32_outline_darkmode.svg) no-repeat;
          }
        }
      }
      .btn-menu-shrink {
        padding-right: 0;
        .icon {
          display: block;
          width: 32px;
          height: 32px;
          background: url(../../images/icon/ic_del_24_outline.svg) no-repeat;
        }
      }
    }

    // depth 2
    .MuiCollapse-wrapper {
      position: relative;
      padding: 10px 0;
      .MuiButtonBase-root {
        min-height: 40px;
        height: auto;
        padding: 6px 20px 6px 55px;

        .MuiTypography-root {
          color: #838a97;
          font-size: 16px;
          font-weight: 400;
          white-space: normal;
        }
        &.selected {
          background: #f6e5eb;
          &:before {
            content: '';
            width: 3px;
            height: 100%;
            background: #a50034;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }

          .MuiTypography-root {
            font-weight: 700;
            color: #525252;
          }
        }
      }
    }
  }
}
