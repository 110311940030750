@import "./font";
@import "./theme";

:root {
  // z-index
  --topBreadCrumbArea: 100;
  --channelArea: 99;
  --currentTime: 5;
  --channelTopText: 2;
  --programHeader: 3;
  --TopBarContainer: 100;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: Roboto, Arial, sans-serif;
  box-sizing: border-box;
}
body {
  background-color: #f7f7f7 !important;

  &.dark {
    background-color: #0c0c0c !important;
  }
}
p {
  margin: 0;
}
[data-rfd-drag-handle-context-id=":r3:"] {
  cursor: url("../../images/icon/ic_drag_hand.png") 10 10 auto;
}

#root {
  & > div {
    height: 100%;
  }
}
.main-container {
  flex: 1;
  min-width: 0;
}

.profile-zone {
  padding: 8px 0;
}

.component-view {
  height: 100vh;
  // padding: 20px;   // 화면 layout 이슈로 인해서 막음
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  background: #fff;
}
.base-Button-root {
  &.only-icon {
    &.bg-no {
      background: none;
      box-shadow: none;
      padding: 0;
    }
  }
  &.icon-text {
    box-shadow: none;
  }
}

.font-red {
  color: #a50034;
}
.sm-sub-text {
  font-size: 12px;
  color: #909090;
}
.MuiTypography-root {
  &.normal-text {
    font-size: 14px;
    color: #000;
  }
}

.btn-layer-popup {
  width: 32px;
  height: 32px;
  background: #dbe3ef url(../../images/icon/ic_sort_24.svg) no-repeat 50%;

  &:active {
    background-color: #c6c6c6;
  }
}

.MuiFormControl-root {
  .MuiFormLabel-root.MuiInputLabel-shrink {
    &.Mui-disabled {
      background: transparent;
      color: #c6c6c6;
    }
  }
}

// TextArea
.MuiTextField-root {
  &.textarea {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input:not(.Mui-disabled):read-only {
        & + .MuiOutlinedInput-notchedOutline {
          border: 1px solid #a3a3a3;
        }
      }
    }
  }
}

// Table
.table-container {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }
  .custom-table {
    table-layout: fixed;

    &.auto {
      width: auto;
      thead {
        .MuiTableCell-root {
          .MuiButtonBase-root {
            white-space: normal;
          }
        }
      }
    }
    &.auto-nowrap {
      width: auto;
      thead {
        .MuiTableCell-root {
          .MuiButtonBase-root {
            white-space: nowrap;
          }
        }
      }
      tbody {
        .MuiTableCell-root {
          white-space: nowrap;
        }
      }
    }

    tbody,
    .tbody {
      .tr {
        &.unsaved {
          .td {
            background: rgba(165, 0, 52, 0.05);
          }
        }
        &.created,
        &.added {
          td {
            background: rgba(33, 150, 243, 0.1);
          }
        }

        &.removed,
        &.deleted {
          td {
            background: rgba(165, 0, 52, 0.05);
          }
        }

        &.updated,
        &.update {
          td {
            background: rgba(13, 148, 136, 0.1);
          }
        }
        &.disabled {
          .td {
            background: rgba(198, 198, 198, 0.5);
          }
        }
      }
    }
    &.data-table {
      width: auto;

      thead {
        th {
          border-left: 1px solid #ddd;
        }
      }
      tbody {
        td {
          border-left: 1px solid #ddd;
          white-space: nowrap;
        }
      }
    }

    &.over-tbl {
      width: auto;
    }

    .icon-draggable {
      flex-shrink: 0;
      margin-right: 4px;
    }

    th,
    td {
      .MuiCheckbox-root {
        & + .MuiFormControlLabel-label {
          margin-left: 8px;
        }
      }
      .MuiLink-root {
        text-decoration: none;
      }
    }

    thead,
    .thead {
      .th {
        border-top: 2px solid #909090;
        background: #f7f7f7;
        color: #525252;
        padding: 9px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -1px;

        &.MuiTableCell-alignRight {
          .MuiTableSortLabel-root {
            flex-direction: row;
          }
        }
        .MuiTableSortLabel-root {
          letter-spacing: -1px;
        }

        .MuiFormControlLabel-root {
          margin-bottom: 0;
          margin-right: 0;

          .MuiFormControlLabel-label {
            margin-left: 0;
          }
          & + .chk-th,
          & + .MuiTableSortLabel-root {
            margin-left: 8px;
          }
        }

        .MuiTableSortLabel-icon {
          opacity: 1;

          path {
            display: none;
          }
        }
        .MuiSvgIcon-root {
          margin-right: 0;
        }
        svg[class*="iconDirectionDesc"] {
          transform: rotate(0);
          background: url(../../images/icon/ic_sort_down_16.svg) no-repeat 50%;

          .dark & {
            background: url(../../images/icon/ic_sort_down_16_darkmode.svg) no-repeat 50%;
          }
        }
        svg[class*="iconDirectionAsc"] {
          transform: rotate(0);
          background: url(../../images/icon/ic_sort_up_16.svg) no-repeat 50%;
        }
      }
      .MuiTableRow-head {
        & + .MuiTableRow-head {
          .th {
            border-top: 0;
          }
        }
      }
    }

    tbody,
    tfoot,
    .tbody {
      .tr {
        &.selected {
          td {
            background-color: rgba(165, 0, 52, 0.05);
          }
        }
      }
      th,
      td {
        padding: 9px;
        background: #fff;
        color: #1e1e1e;
        font-size: 1.4rem;
        border-bottom: 1px solid rgba(107, 107, 107, 0.2);
        word-break: break-all;
        .MuiTypography-root {
          color: #000;
          font-size: 1.4rem;

          &.MuiLink-root {
            color: #2196f3;
          }
        }
      }
    }
    tfoot {
      td {
        padding: 10px 14px;
        background: #f7f7f7;
      }
    }
  }
  tbody {
    .MuiTableCell-root {
      .font-red {
        color: #b91c1c;
      }
      .font-blue {
        color: #0d9488;
      }
    }
    .td,
    td {
      .td-tooltip-wrap {
        display: flex;
      }

      &.noData {
        border-bottom: 0;
      }
      .custom-image {
        display: block;
      }

      .MuiTypography-root {
        &.val-deleted {
          color: #b91c1c;
        }
        &.val-created {
          color: #0077b5;
        }
        &.val-updated {
          color: #0d9488;
        }
      }
      .ready {
        color: #0077b5;
      }
      .done,
      .updated,
      .select,
      .green {
        color: #0d9488;
      }
      .unselect {
        color: #b91c1c;
      }
      &.yellow {
        background: rgba(245, 158, 11, 0.1);
      }
      &.red {
        background: rgba(185, 28, 28, 0.1);
      }
      &.green {
        background: rgba(13, 148, 136, 0.1);
      }
      &.blue {
        background: rgba(33, 150, 243, 0.1);
      }
      .greenCircle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #0d9488;

        &.edit {
          background: #ddd;
        }
      }
      .zero-under,
      .fail {
        color: #b91c1c;
      }
    }
  }
  .table-foot {
    position: sticky;
    bottom: 0;
    width: 100%;
    .tr {
      display: flex;

      .td {
        background: #f7f7f7;
      }
    }
  }
}
.infinite-scroll-table {
}
.infinite-scroll-tbody {
  position: absolute !important;
  height: calc(100% - 150px) !important;
  &::-webkit-scrollbar {
    width: 6px; /*스크롤바의 너비*/
  }
  &.bottom-y {
    margin-bottom: 82px;
  }
  & > div {
    .MuiTableRow-root:last-of-type {
      margin-bottom: 82px;
    }
    .MuiTableCell-root {
      display: flex;
      align-items: center;
    }
  }
  .MuiTableCell-root {
    padding: 9px;
    background: #fff;
    color: #1e1e1e;
    font-size: 1.4rem;
    border-bottom: 1px solid rgba(107, 107, 107, 0.2);
    word-break: break-all;
    .MuiTypography-root {
      color: #000;
      font-size: 1.4rem;

      &.MuiLink-root {
        color: #2196f3;
      }
    }
  }
}
.MuiInputBase-root {
  .MuiInputBase-root {
    textarea {
      border: 1px solid #bbb;
      border-radius: 4px;
    }
  }
}
.iconCheck {
  margin: 0 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("../../images/icon/ic_check1_24_outline.svg") no-repeat center center;
  background-size: contain;
}
.iconX {
  margin: 0 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("../../images/icon/ic_close_24_outline_red.svg") no-repeat center center;
  background-size: contain;
}

// Header - TOP Area
.top-title-container {
  position: sticky;
  left: 0;
  top: 80px;
  width: 100%;
  padding: 14px 24px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: var(--topBreadCrumbArea);
  .dark & {
    border-color: $darkmode-border;
  }
  .top-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title-area {
    .title {
      color: #000;
      font-weight: 400;
      font-size: 1.8rem;

      .dark & {
        color: $darkmode-text;
      }

      &.link {
        text-decoration: none;
        border: 0;
        &:before {
          content: "";
          display: inline-block;
          width: 32px;
          height: 32px;
          margin-right: 12px;
          vertical-align: middle;
          background: url(../../images/icon/ic_back_32_outline.svg) no-repeat;
        }
      }
    }
  }
}

//Contents Area
.content-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 190px);
  margin: 24px;
  background: #f7f7f7;

  &.height-auto {
    height: auto;
  }
}

//  팝업 내 input, select, datepicker wrap
.field-box {
  padding: 8px 0;

  &.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .input-sub-text {
    margin-top: 3px;
    color: #909090;
    font-size: 12px;
    padding-left: 12px;
  }
  .MuiFormControl-root {
    margin: 0;
  }
}

//Tab
.tab-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .MuiTabs-root {
    flex-shrink: 0;
  }
  .tab-panel {
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
  }
  .panel-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.tab-panel {
  padding-top: 24px;
  // padding-bottom: 20px;

  .panel-wrap {
    padding-right: 2px;
  }
  .detail-list-container {
    margin: 0;
  }
}

// dialog
.MuiDialogContent-root {
  .info-list-container {
    &.MuiList-root {
      margin-bottom: 0;
      padding: 17px 0;
    }
  }
}
.pop-program-detail {
  .MuiDialogContent-root {
    .media-detail-container {
      overflow-y: auto;
    }
  }
}

.text-type {
  .text {
    font-size: 14px;
    color: #000;
  }
}

// Whitebox
.white-box {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  &.single-box {
    height: 100%;
    overflow: hidden;
  }

  &.auto-box,
  &.filter-box {
    overflow: initial;
    height: auto;
    margin-bottom: 24px;
  }

  &.segment-box {
    height: auto;
    .title-wrap {
      border-bottom: 0;
    }
  }

  .box-content {
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .content-inner-wrap {
      padding: 0 24px;
      padding-bottom: 76px;
      height: 100%;
      overflow-y: auto;

      &.no-page-number {
        padding-bottom: 0;
        margin-bottom: 24px;
      }
      &.overflow-auto {
        overflow: auto;
      }
    }
    .detail-list-container {
      &.type-category {
        overflow-y: auto;
        margin: 0;
      }
    }
  }

  .inner-wrap {
    padding: 0 24px;
    .white-box-content-top-area {
      padding: 0;
    }
  }

  .btn-expand {
    position: absolute;
    right: -13px;
    top: 50%;
    min-width: initial;
    width: 24px;
    height: 24px;
    padding: 0;
    background: url("../../images/icon/ic_down3_24_outline.svg") no-repeat;
    box-shadow: none;
  }
  .box-title-area {
    display: flex;
  }
}

.content-inner-wrap {
  &.overflow-auto {
    overflow: auto;
  }
}

// White box 패딩 제거 클래스 : white-box-wrap
.white-box-wrap {
  padding: 0;

  .tab-content-area {
    .MuiTabs-root {
      padding-top: 24px;
    }
    .MuiTabs-flexContainer {
      .MuiTab-root {
        margin: 0 24px;
      }
    }
  }
}
.title-wrap {
  padding: 2.4rem 2.4rem 1.2rem;
  border-bottom: 0.1rem solid #ddd;
  .dark & {
    border-bottom: 1px solid #262626;
  }
  &.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flex-end {
      align-items: flex-end;
    }

    .left-area {
      flex-grow: 1;
    }
    .right-area {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
  .count-all {
    .text {
      font-size: 14px;
      font-weight: 400;
    }
    .label {
      color: #909090;
      margin-right: 8px;
    }
    .value {
      color: #a50034;
      font-weight: 700;
    }
  }
  .bot-title-text,
  h2.MuiTypography-root {
    display: inline-block;
    color: #4e5258;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.8rem; /* 155.556% */
  }

  .box-title-area {
    .select-count-wrap {
      color: #909090;
      font-size: 1.2rem;
      font-weight: 400;
      margin-left: 1.2rem;

      .set-color {
        color: #2196f3;
      }
    }
  }
  .box-top-label {
    font-size: 18px;
    font-weight: 700;
    color: #525252;
    line-height: normal;
  }
  .box-title-bottom-text {
    font-size: 12px;
    color: #909090;
  }
}
// white-box 내에 테이블 위 컴포넌트 wrap up class
.white-box-content-top-area {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .base-Button-root,
  .MuiFormControl-root {
    margin-bottom: 8px;
  }
  .file-upload-container {
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }

  .left-area {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    margin-right: 8px;

    &.both {
      align-items: center;
      .total-count {
        margin-bottom: 0;
      }
    }
    &.column {
      flex-direction: column;

      .total-count {
        margin-bottom: 0;
      }
    }
  }
  .right-area {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    justify-content: flex-end;

    .button-group {
      justify-content: flex-end;
    }
  }
  .total-count {
    .value {
      padding-left: 4px;
    }
  }
}

.right-area,
.left-area {
  &.grow-2 {
    flex-grow: 2;
  }
  &.grow-3 {
    flex-grow: 3;
  }
  &.grow-4 {
    flex-grow: 4;
  }
}

.info-output-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.group-title-area {
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  margin-bottom: 24px;
  .group-title {
    font-size: 16px;
    font-weight: 700;
    color: #525252;
  }
}

.MuiTypography-root {
  &.field-label {
    // width: fit-content;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000;

    .dark & {
      color: #fff;
    }

    &.required {
      &:after {
        content: "*";
        color: #a50034;
      }
    }
  }
  &.sm-sub-text {
    font-size: 12px;
  }
  &.notice-text {
    font-size: 14px;
    color: #6b6b6b;
  }
}

.component-wrap {
  display: flex;
  gap: 12px 8px;
  flex-wrap: wrap;

  & + .component-wrap {
    margin-top: 12px;
  }

  .col {
    width: 100%;
    display: flex;
    gap: 8px;

    &.auto {
      max-width: none;
    }
  }
  &.col-2 {
    .col {
      width: 49%;

      &.auto {
        width: auto;
      }
    }
  }
  &.col-3 {
    .col {
      width: 32%;
      max-width: 400px;

      &.auto {
        width: auto;
      }
    }
  }
  &.col-4 {
    .col {
      min-width: 24%;
      max-width: 320px;

      &.auto {
        width: auto;
      }
    }
  }
  &.col-5 {
    .col {
      width: 100%;
      max-width: 220px;

      &.auto {
        width: auto;
        max-width: none;
      }
    }
  }
  &.col-6 {
    .col {
      max-width: 180px;

      &.auto {
        width: auto;
        max-width: none;
      }
    }
  }
}
.single-box {
  .tab-content-area {
    height: 100%;
    overflow: hidden;
  }
  .box-content {
    overflow-y: auto;
  }
}

.content-pack-area {
  height: 100%;
  overflow: hidden;

  .MuiGrid-item {
    height: 100%;
  }
}
.monthlyPicker-container {
  .MuiFormControl-root {
    height: 40px;
    .MuiInputBase-root {
      height: 40px;
    }
  }
}
.rangePicker-container {
  display: flex;
  align-items: center;
  gap: 8px;
  .wave {
    font-size: 18px;
    color: #525252;
  }
}

.pop-content {
  .Pagination-bottom-container {
    position: static;
  }
}

.section-container {
  width: 100%;
  margin-bottom: 24px;
  margin-left: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .sub-title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: var(--Basic-Cool-Grey4_e);

    &.required {
      &:after {
        content: "*";
        font-size: inherit;
        font-weight: inherit;
        color: #a50034;
        line-height: inherit;
      }
    }
  }
}

.td-tooltip-wrap {
  & > span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
  }
}
.tooltip-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../../images/icon/ic_info_24_outline_primary.svg) no-repeat 50%;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;

  .image-box {
    display: block;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
  }
}

.MuiRadioGroup-root {
  .MuiFormControlLabel-root {
    margin: 0;
  }
  &.align-h {
    display: flex;
    flex-direction: row;
  }
}

.scroll-area {
  overflow-y: auto;
  padding-right: 2px;
}

// Inspect
.inspect {
  .white-box {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .box-top-label {
      display: flex;
      .label {
        margin-right: 4px;
        color: #525252;
        font-size: 1.8rem;
        font-weight: 700;
      }
      .value {
        color: #a50034;
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }
}

.form-wrap-box {
  display: flex;
  .form-area {
    .MuiFormControl-root {
      width: 100%;
    }
    &.left {
      width: 100%;
    }
    &.right {
      flex-shrink: 0;
      .button-container {
        margin-bottom: 12px;
      }
      .button-group {
        height: 100%;
        align-items: flex-end;
      }
    }
  }
}

.channel-count {
  .form-wrap-box {
    align-items: flex-end;
    gap: 8rem;
    .form-area {
      &.left {
        flex: 1 0;
      }
      &.right {
        height: 100%;
        flex: 0;
        align-items: flex-end;
      }
    }

    .box-row {
      display: flex;
      gap: 8px;
      & + .box-row {
        margin-top: 1.2rem;
      }
    }
  }
}
.channel-map {
  .form-wrap-box {
    align-items: flex-end;

    .form-area.left {
      width: 100%;
    }
  }
}
.monthly-picks-wrap {
  display: flex;
  align-items: center;

  .pick-name {
    font-size: 1.8rem;
    font-weight: 700;
    .label {
      color: #525252;
      margin-right: 4px;

      .dark & {
        color: $darkmode-text;
      }
    }
    .value {
      color: #a50034;
    }
  }
  .img {
    margin-right: 2rem;
  }
}

.vod-detail,
.program-detail,
.channel-detail {
  .detail-list-container {
    margin: 0 0 40px;

    &.full-width {
      max-width: 100%;
    }
  }
  .box-content {
    height: 100%;
  }
}
.required {
  &:after {
    content: "*";
    font-size: 14px;
    color: #a50034;
  }
}

.detail-list-container {
  .MuiGrid-container {
    width: auto;

    & > .MuiGrid-item {
      margin-bottom: 12px;
      max-width: none;
    }
    & > .MuiGrid-grid-xs-12 {
      margin-top: 0;
    }
    .MuiGrid-root {
      &.red {
        .text {
          color: #a50034;
        }
      }
      &.bold {
        .text {
          font-weight: 700;
        }
      }
    }
  }
  .list-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;

    &.mb-0 {
      margin-bottom: 0;
    }
    &.none-border {
      .value {
        border-bottom: 0;
      }
    }
    &.alignTop {
      .MuiGrid-item {
        align-items: flex-start;
        .label {
          padding-top: 12px;
        }
      }
    }

    .logo-image-container {
      display: flex;
      gap: 12px;
    }
    .image-value-area {
      display: flex;
      align-items: center;
      width: 100%;
      .value {
        .image-wrap {
          display: flex;
          gap: 20px;
        }
      }
    }
  }
  .label {
    width: 122px;
    margin-right: 12px;
    flex-shrink: 0;
    font-size: 1.4rem;
    color: #909090;

    .text {
      font-size: 1.4rem;
      color: #909090;

      &.bold {
        color: #000;
      }
    }

    &.width-auto {
      width: auto;
    }
  }
  .value-wrap {
    width: 100%;
    .extra-info {
      display: flex;
      margin-top: 4px;
      .divider {
        margin: 0 3px;
      }
      .text {
        color: #0077b5;
        font-size: 1.2rem;
      }
    }
  }
  .value {
    width: 100%;
    padding: 14px 0;
    min-height: 48px;
    border-bottom: 1px solid #ddd;

    &.no-border {
      border-bottom: 0;
    }
    .dark & {
      border-color: $darkmode-border;
    }
    > .text {
      font-size: 1.4rem;
      font-weight: 400;
      color: #525252;
    }
  }
}

.vod-detail {
  .MuiGrid-item {
    .MuiGrid-item {
      align-items: center;
    }
  }
}
.thumb-list-container {
  padding: 0 0 56px;
  &:not(:first-child) {
    padding-top: 48px;
    border-top: 1px solid #ddd;
  }
  .thumb-list-box {
    max-height: 904px;
    overflow-y: auto;

    margin: 0 20px 0 24px;
    padding-right: 4px;
  }
  .box-title {
    padding: 0 24px 24px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #000;
  }
}
.thumb-grid-container {
  &.MuiGrid-root {
    width: auto;
    margin-left: 0;
    margin-top: 0;

    gap: 56px 2%;
    .MuiGrid-item {
      padding: 0;
      width: 32%;

      .img-box {
        width: 100%;
        margin-bottom: 12px;

        img {
          width: 100%;
        }
      }
      .info-item {
        display: flex;
        gap: 8px;
        margin-bottom: 4px;
        &.group {
          .item-value {
            font-weight: 700;
          }
        }
      }
      .MuiLink-root {
        word-break: break-all;
      }
      .item-label {
        flex-shrink: 0;
        min-width: 70px;
        color: #525252;
        font-size: 1.4rem;
        font-weight: 700;

        .media & {
          min-width: 120px;
        }
      }
      .item-value {
        color: #000;
        font-size: 1.4rem;
        font-weight: 400;

        .dark & {
          color: #fff;
        }
      }
    }
  }
}
.align-set-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.total-count {
  display: flex;
  align-items: center;
  gap: 2px;
  .label {
    color: #909090;
    font-size: 1.4rem;
    font-weight: 700;
  }
  .value {
    color: #a50034;
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.btn-drop-paper {
  .MuiList-root {
    .text-label {
      width: 100%;
      padding: 6px 12px;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
    }
    .MuiMenuItem-root {
      padding: 6px 12px;

      .MuiFormControlLabel-root {
        width: 100%;
      }
    }
    .text-value {
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;  // 커서 추가
    }
  }
}
.channel-count-container {
  .top-area {
    display: flex;
    align-items: center;
    margin: 8px 0;
    .info-item {
      color: #909090;
      font-size: 1.2rem;

      .label {
        margin-right: 8px;
      }
      .value {
        font-weight: 700;
      }
    }
    .divider {
      height: 8px;
      width: 1px;
      background: #ddd;
      margin: 0 16px;
    }
    .country {
      .value {
        color: #0077b5;
      }
    }
    .cp {
      .value {
        color: #0d9488;
      }
    }
  }
  .MuiTable-root {
    tfoot {
      td {
        font-weight: 700;

        &:nth-child(1) {
          color: #000;
        }
        &.total-sum {
          color: #2196f3;
        }
      }
    }
  }
}

.pop-program-detail,
.pop-channel-detail {
  .detail-list-container {
    .section {
      width: auto;
    }
  }
}

.pop-channel-detail {
  .detail-content {
    width: 100%;

    .MuiTabs-flexContainer {
      padding: 0 24px;
    }

    .MuiTabs-root {
      width: 100%;
    }
    .detail-list-container {
      .MuiGrid-container > .MuiGrid-item {
        margin-bottom: 0;
      }
    }
  }
}

.media-detail-container {
  .media-inner-wrap {
    padding-bottom: 40px;
  }
  .media-wrap {
    & + .media-wrap {
      margin-top: 40px;
    }
    .title-area {
      margin-bottom: 24px;
    }
    .category-title {
      color: #525252;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
  }
  .media-item-wrap {
    display: flex;
    gap: 20px;

    & + .media-item-wrap {
      margin-top: 24px;
    }
    .item-image-area {
      flex-shrink: 0;
    }
    .item-info-area {
      width: 100%;
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &.hor {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        padding: 0;
      }
    }
    .item-row {
      display: flex;

      .text {
        font-size: 1.2rem;
        font-weight: 400;

        &.label {
          color: #909090;
        }
        &.value {
          padding-left: 2px;
          color: #525252;
          font-weight: 700;
        }
      }
    }
  }
}
.img-url-wrap {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img-size {
    font-size: 1.2rem;
    color: #525252;
    font-weight: 400;

    .bold {
      font-weight: 700;
    }
  }

  .image-button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .base-Button-root {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.detail-list-container {
  margin: 24px 0 0;

  &.type-category {
    .MuiGrid-item {
      margin-bottom: 8px;
    }
  }

  .info-value-wrap {
    display: flex;
    align-items: center;
    width: 100%;

    &.align-top {
      align-items: flex-start;
    }
  }

  .section {
    width: 100%;
    flex-wrap: nowrap;
  }
}

.bottom-shadow-wrap {
  .button-group {
    width: auto;

    flex-wrap: wrap;
    justify-content: space-between;
  }
  .btn {
    .text {
      font-size: 14px;
    }
  }
}
.white-box-container {
  display: flex;
  gap: 20px;

  &.align-hor {
    height: 100%;
    overflow: auto;
    .white-box {
      margin: 0;

      &:nth-child(1) {
        max-width: 15%;
        min-width: 220px;
        flex: 1;
      }
      &:nth-child(2) {
        max-width: 31%;
        min-width: 450px;
        flex: 1 2;
      }
      &:nth-child(3) {
        flex: 1;
        min-width: 500px;
      }

      .box-content {
        flex: 1;
        overflow: hidden;
        .inner-wrap {
          height: 100%;
          display: flex;
          flex-direction: column;

          &.no-page-number {
            .table-container {
              padding-bottom: 0;
              margin-bottom: 24px;
            }
          }
          .table-container {
            padding-bottom: 76px;
          }
        }
        .bottom-shadow-wrap {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }

      &.collapsed {
        width: 0;
        min-width: 0;
        flex: 0;
        margin: 0 7px;
        .btn-expand {
          transform: rotate(180deg);
          height: 24px;
        }
        .fold-content {
          visibility: hidden;
          .left-area {
            display: none;
          }
          .MuiTableCell-root {
            .MuiTypography-root {
              display: none;
            }
          }
          & > * {
            margin: 0;
          }
        }
      }

      &.expanded {
        width: auto; // 펼쳤을 때의 최대 높이 (필요에 따라 조정)
        flex: 1;
      }
    }
  }

  .mode-label {
    font-size: 12px;
  }
}
.fold-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.feed-info {
  .feed-list-container {
    height: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    overflow-y: auto;

    .list-box {
      padding-right: 4px;
    }
  }
}
.select-feed {
  .title-value {
    padding-left: 4px;
    color: #a50034;
    font-size: 18px;
    font-weight: 700;
  }
}
.view-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .left-area {
    width: 50%;
    .MuiTypography-root {
      font-size: 12px;
      margin-left: 12px;
    }
  }
  .right-area {
    justify-content: flex-end;
    .MuiPaper-root {
      width: auto;
    }
  }
}
.select-channel {
  .box-content {
    height: auto;
  }
  .content-inner-wrap {
    padding-bottom: 0;
  }
  .box-title-bottom-text {
    .font-red {
      font-weight: 700;
    }
  }
}
.component-align-wrap {
  width: 100%;
  display: flex;
  gap: 8px;
  .field-item {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    &.auto {
      flex: 0;
    }
  }
}

.category-detail {
  .detail-list-container {
    margin: 0 0 40px;
    padding: 0 24px;
  }
}

.info-list-container {
  &.MuiList-root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    .list-item {
      display: flex;
      width: auto;
      padding: 0;
      flex-shrink: 0;

      .MuiTypography-root {
        font-size: 14px;
        font-weight: 700;
      }
      .label {
        color: #525252;

        &:after {
          content: ":";
          padding: 0 3px;
        }
      }
      .value {
        color: #2196f3;
      }
    }
    .divider {
      width: 1px;
      height: 8px;
      margin: 0 12px;
      background: #ddd;
      &:last-child {
        display: none;
      }
    }
  }
}
.type-category {
  &.detail-list-container {
    height: 100%;
    padding: 20px 24px;

    .info-value-wrap {
      &.align-top {
        align-items: flex-start;

        .label {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      &.no-border {
        .value {
          border-bottom: 0;
          min-height: auto;
          padding: 0;
        }
      }
      &.bold {
        .label {
          color: #000;
        }
      }
    }
    .value {
      padding: 6px 0;
    }
  }
}
.upload-info-text {
  &.MuiTypography-root {
    padding-top: 0.4rem;
    font-size: 1.2rem;
    color: #909090;

    strong {
      font-weight: 700;
      color: #000;
    }
  }
}

.switch-with-label-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // gap: 47px;
}
.category-organize-container {
  .table-container {
    .thumb-img {
      width: 122px;
      img {
        width: 100%;
      }
    }
  }
}
.show-count-wrap {
  font-size: 1.4rem;
  font-weight: 400;

  .label {
    padding-right: 8px;
    font-size: 1.4rem;
    color: #909090;
  }
  .value {
    font-size: 1.4rem;
    font-weight: 700;
    color: #a50034;
  }
}
.upload-box {
  position: relative;
  min-height: 30px;
  padding: 16px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f7f7f7;

  .dark & {
    background: #282828;
  }

  .upload-total-count {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    color: var(--Basic-Cool-Grey4_e);

    > span {
      color: $HeritageRed;
    }
  }

  .upload-file-list {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .box-content {
    position: relative;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 66px);

    &.align-row {
      flex-direction: row;
    }

    .left-area {
      margin-right: 24px;
    }
    .right-area {
      overflow: hidden;
    }
  }
  .upload-info-wrap {
    .file-name {
      padding-right: 40px;
      margin-bottom: 12px;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file-list-area {
      .item {
        color: #c6c6c6;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
  }
}

.common-auto {
  .MuiRadioGroup-root {
    &.align-h {
      gap: 48px;
    }
  }
}
.layout-container {
  .row {
    display: flex;
    align-items: center;
  }
  .left-area {
    min-width: 120px;
  }
  .right-area {
    width: 100%;
  }
  .filter-label {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: 20px;
  }
}
.update-date {
  margin-top: 8px;
  .date {
    font-size: 14px;
    color: #909090;

    &.value {
      padding-left: 4px;
    }
  }
}
.MuiTableBody-root {
  .MuiTableCell-root {
    .td-text {
      margin-right: 4px;
      &:after {
        content: ",";
      }
    }
    .lv1:after {
      content: "*";
      font-size: 14px;
      color: #a50034;
    }
    .lv2:after {
      content: "**";
      font-size: 14px;
      color: #a50034;
    }
  }
}

.message-box {
  border-radius: 4px;
  padding: 24px;
  background: #f7f7f7;

  .list-text {
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
  .point {
    color: #a50034;
  }
}

.no-data-area {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #525252;
  }
}

.bottom-shadow-wrap {
  padding: 16px 24px;
  border-radius: 0 0 8px 8px;
  background: #fff;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
  .dark & {
    background-color: #1b1b1b;
  }
}

.bulk-container {
  display: flex;
  .left-area {
    width: 33%;
  }
  .right-area {
    width: 67%;
  }
}
.upload-area {
  width: 520px;
}
.notice-text-list {
  .list-item {
    padding: 0;
    color: #909090;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
}
.sum-count-container {
  display: flex;
  align-items: center;
  margin-left: 24px;

  .text {
    font-size: 12px;
    color: #909090;
  }

  .count-list {
    padding: 0;
    display: flex;

    .MuiListItem-root {
      width: auto;
      padding: 0;
      color: #909090;
      flex-shrink: 0;
      font-size: 12px;
    }
    .count-value {
      padding-left: 8px;
      font-size: 12px;
      font-weight: 600;
      &.green {
        color: #0d9488;
      }
      &.red {
        color: #a50034;
      }
      &.blue {
        color: #0077b5;
      }
    }
  }
}

.title-selected-container {
  $boxHeight: 100%;
  .content-empty-container {
    height: $boxHeight;
    padding-top: 66px;

    &.hide {
      display: none;
    }
    .empty-message {
      text-align: center;
      color: #525252;
      font-size: 14px;
    }
  }
  .content-container {
    height: $boxHeight;

    &.hide {
      display: none;
    }
  }
}

.field-wrap {
  padding: 24px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
}

.field-label-container {
  align-items: center;

  .field-value {
    padding: 14px 0;
    &:not(.no-border) {
      border-bottom: 1px solid #ddd;
    }
    .text {
      font-size: 1.4rem;
      color: #525252;
    }
  }
}

.result-value {
  .label {
    margin-right: 3px;
  }
}

.layout-label-container {
  .detail-list-container {
    margin-top: 0;
  }
  .info-value-wrap {
    display: flex;
    .label {
      width: 160px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
    }
    .value {
      min-height: auto;
      border-bottom: 0;
      padding: 0;

      &.border {
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

.popup-pager-rel,
.snapshot-pop {
  .Pagination-bottom-container {
    position: relative;
    box-shadow: none;
    margin-top: 1.2rem;
    margin-bottom: -0.8rem;
  }
}

.chart-box,
.segment-box {
  width: 100%;
  // flex-shrink: 0;

  .segment-box-title {
    font-size: 16px;
    font-weight: 700;
    color: #525252;
  }

  .box-content {
    padding: 2.4rem;
    padding-top: 1.2rem;
  }
}

.chart-box {
  height: 100%;
  .title-wrap {
    border-bottom: 1px solid #ddd;
  }
  .box-content {
    overflow-y: auto;
    padding-bottom: 24px;
  }
  .chart-item {
    padding-left: 0;
    padding-right: 0;
  }
}
.group-addbox-container {
  margin-bottom: 12px;
  display: flex;
  &.group-b,
  &.group-c {
    &:before {
      content: "";
      flex-shrink: 0;
      width: 77px;
      height: 64px;
      margin: 12px 12px 0 22px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    .segment-box-title {
      color: #0077b5;
    }
  }
  &.group-b {
    .segment-box-title {
      color: #0077b5;
    }
  }
  &.group-c {
    .segment-box-title {
      color: #0d9488;
    }
  }
}
.chart-container {
  min-height: 200px;
  height: 100%;

  .chart-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    &.align-center {
      justify-content: center;
      align-items: center;
    }
  }
}
.data-table-container {
  width: 100%;
  .data-table-view {
    width: 100%;
    overflow-x: auto;
  }
}

.tabs-icon-container {
  .MuiTabs-root {
    min-height: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .MuiTab-root {
    padding: 0.4rem;
    min-width: auto;
    max-width: none;
    min-height: auto;
    background-color: rgba(219, 227, 239, 0.6);

    &.Mui-selected {
      background-color: #fff;
    }
  }
  .MuiTabs-indicator {
    height: 0;
    width: 0;
    background: none;
    display: none;
  }
}

.version-number {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 24px;
  .label {
    color: #525252;
    font-size: 14px;
    font-weight: 700;

    &:after {
      content: ":";
      padding: 0 4px;
    }
  }
  .value {
    color: #a50034;
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.tooltip-info-box {
  .label {
    font-size: 1.4rem;
  }
}

/* Edit 안내 문구 영역 */
.detail-desc-container {
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}

.content-tab-container {
  .label {
    padding: 14px 0 0;
  }
  .value {
    padding: 0;
  }
}

.notification-container {
  height: 100%;
  .white-box {
    width: 100%;
    max-width: 1240px;
    margin: auto;

    .box-content {
      height: calc(100% - 73px);
    }
  }
  .MuiTableRow-root {
    &.expanded {
      .MuiTableCell-root {
        background: #f2f2f2;
      }
    }
  }
  .tab-panel {
    padding-top: 0;
  }
  .down-list-wrap {
    .file-item {
      line-height: 2rem;
      margin-bottom: 0.8rem;

      a {
        display: flex;
        align-items: center;
        gap: 8px;

        &:before {
          content: "";
          width: 24px;
          height: 24px;
          display: inline-block;
          background: url(../../images/icon/ic_download_24_outline.svg) no-repeat;
        }
      }
    }
  }
  .down-box-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .down-box-body {
    overflow-y: auto;
    max-height: 160px;
  }
  .dropwdown-content-wrap {
    .MuiTableCell-root {
      background: #f7f7f7;
    }

    .MuiTableCell-root {
      padding: 0;
      border-bottom: 0;
    }
    .dropwdown-box {
      transition: height 0.2s;
    }
    .faq-title {
      padding-bottom: 12px;
      margin-bottom: 12px;
      color: #525252;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      border-bottom: 1px solid #ddd;
    }
    .faq-text-body {
      .faq-text {
        font-size: 14px;
        padding-bottom: 40px;
        color: #525252;
      }
    }
    .dropwdown-content {
      margin: 24px 24px 12px;
    }
  }
}
.btn-dropdown {
  &.fileDropDownArrow {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }
}

.toggle-btn-wrap {
  &.time-period {
    gap: 0;
    border: 1px solid #737f90;
    border-radius: 4px;

    .base-Button-root.tertiary {
      border: 0;
      border-radius: 0;

      &:hover {
        border: 0;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
    .base-Button-root.outline {
      border: 0;
    }
  }
}

.partner-statistic {
  min-width: 1200px;
  .content-wrap {
    height: auto;
  }
  .component-wrap {
    .col {
      max-width: 185px;
    }
    .col-picker,
    .col-toggle {
      max-width: max-content;
    }
  }
}
.chart-board-container {
  .button-area {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .tab-box {
      min-width: 156px;
      padding: 0;
      border-radius: 10px;
      border: 1px solid #c6c6c6;
      background: #fff;
      cursor: pointer;

      &.active {
        background: #a50034;
        .label,
        .value {
          color: #fff;
        }
        .label:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' fill='white'/%3E%3Cpath d='M11 7H13V9H11V7Z' fill='black'/%3E%3Cpath d='M11 11H13V17H11V11Z' fill='black'/%3E%3C/svg%3E");
        }
      }

      .box-wrap {
        padding: 24px;
        text-align: left;
        border-radius: 8px;
      }
      .label {
        display: block;
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 2px 0;

        &:after {
          content: "";
          vertical-align: middle;
          display: inline-block;
          margin-left: 8px;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' fill='%23525252'/%3E%3Cpath d='M11 7H13V9H11V7Z' fill='white'/%3E%3Cpath d='M11 11H13V17H11V11Z' fill='white'/%3E%3C/svg%3E");
        }
      }
      .value {
        display: block;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
      }

      &:disabled {
        cursor: initial;
        background: #e2e2e2;
        opacity: 0.3;
        .label {
          color: #6b6b6b !important;

          &:after {
            opacity: 0.3;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' fill='%23525252'/%3E%3Cpath d='M11 7H13V9H11V7Z' fill='white'/%3E%3Cpath d='M11 11H13V17H11V11Z' fill='white'/%3E%3C/svg%3E");
          }
        }
        .value {
          opacity: 0.3;
          color: #000;
        }
      }
    }
  }

  .board-chart-wrap {
    .chart-area {
      display: flex;
      gap: 20px;
      .white-box {
        width: 100%;
      }
      .box-content {
        align-items: center;
      }
    }

    .bar-hor-chart {
      width: 100%;
      padding-top: 45px;

      canvas {
        height: 83% !important;
      }
    }
    .bar-line-chart {
      width: 100%;
      height: 385px;
      padding-top: 45px;

      canvas {
        width: 100% !important;
        height: 85% !important;
      }
    }
  }
}
